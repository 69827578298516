import * as React from "react"

function CssSvg(props) {
  return (
    <svg height={64} viewBox="0 0 298 420" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          fill="#1572B6"
          d="M27.071 386.155L0 82.535 297.45 82.535 270.354 386.106 148.543 419.876z"
        />
        <path
          fill="#33A9DC"
          d="M148.727 394.064L247.154 366.776 270.311 107.361 148.727 107.361z"
        />
        <g transform="translate(54.929 141.865)">
          <path
            fill="#FFF"
            d="M93.797 75.496L143.072 75.496 146.475 37.364 93.797 37.364 93.797 0.125 93.926 0.125 187.172 0.125 186.279 10.116 177.127 112.732 93.797 112.732z"
          />
          <path
            fill="#EBEBEB"
            d="M94.02 172.204L93.857 172.25 52.385 161.051 49.733 131.353 29.582 131.353 12.354 131.353 17.57 189.82 93.848 210.996 94.02 210.948z"
          />
          <path
            fill="#FFF"
            d="M139.907 111.156L135.423 161.026 93.891 172.236 93.891 210.978 170.23 189.82 170.79 183.53 177.268 111.156z"
          />
          <path
            fill="#EBEBEB"
            d="M93.926 0.125L93.926 23.253 93.926 37.272 93.926 37.364 4.098 37.364 4.098 37.364 3.979 37.364 3.232 28.994 1.535 10.116 0.645 0.125z"
          />
          <path
            fill="#EBEBEB"
            d="M93.797 75.5L93.797 98.629 93.797 112.646 93.797 112.738 52.969 112.738 52.969 112.738 52.85 112.738 52.104 104.369 50.406 85.491 49.516 75.5z"
          />
        </g>
        <path
          className="dark-path"
          d="M233.093 15.546h-24.688l25.674 27.859v13.261h-52.882V41.14h25.669l-25.669-27.86V0h51.896v15.546zm-62.378 0h-24.688L171.7 43.405v13.261h-52.882V41.14h25.672l-25.672-27.86V0h51.897v15.546zm-61.768.631h-26.64V40.49h26.64v16.176H63.373V0h45.574v16.177z"
          fill="#000"
        />
      </g>
    </svg>
  )
}

export default CssSvg
